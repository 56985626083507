// INDICATORS DEFAULT PARAMS
export const MOVING_AVERAGE_DEFAULT_PERIOD = 10;
export const BOLLINGER_DEFAULT_PERIOD = 20;
export const BOLLINGER_DEFAULT_STD = 2;

export const MACD_FAST_PERIOD = 12;
export const MACD_SLOW_PERIOD = 26;
export const MACD_SIGNAL_PERIOD = 9;
export const MACD_SOURCE = 'close';
export const MACD_COLORS = {
  positive: 'rgb(87, 225, 90)',
  negative: 'rgb(242, 142, 44)',
  signalLine: '#2962FF',
};

// IN SECONDS
export const ONE_MINUTE = 60;
export const ONE_HOUR = 60 * ONE_MINUTE;
export const ONE_DAY = 2 * ONE_HOUR;
export const ONE_MONTH = 30 * ONE_DAY;
